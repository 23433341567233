<template>
  <div>
    <table-list
      class="tableList"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="list"
      :title="tableTitle"
      :tabs-list="tabsList"
      :options="{ selection: true, operatesWidth: 70 }"
      :columns="columns(this)"
      :operates="operates(this)"
      :btns="btns(this)"
      :pager="pager"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>

<script>
//表格tabs
const tabsList = [
  {
    prop: 'reviewStatus',
    activeValue: '',
    children: [
      { value: 0, label: '待审核' },
      { value: 1, label: '已通过' },
      { value: 2, label: '未通过' },
    ],
  },
]
//表格标题
const tableTitle = '证书列表'
const btns = _this => [
  {
    label: '添加证书项目',
    method: _this.handleAdd,
  },
  {
    label: '导出',
    method: _this.handleExport,
  },
  {
    label: '删除',
    type: 'danger',
    method: _this.handleDel,
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '证书名称',
    prop: 'certName',
  },
  {
    label: '证书分类',
    prop: 'productAttributeCategoryId',
    type: 'select',
    children: _this.selectTypeList.map(item => ({
      value: item.productAttributeCategoryId,
      label: item.name,
    })),
  },
  {
    label: '地区',
    type: 'treeSelect',
    prop: 'cityCode',
    options: _this.total,
    optionProps: {
      value: 'value',
      label: 'label',
      children: 'children',
    },
  },
  {
    label: '创建时间',
    prop: 'time',
    type: 'picker',
    pickerDate: _this.pickerDate,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'certName',
    label: '证书名称',
    minWidth: '76px',
    twoLines: true,
  },
  {
    prop: 'productAttributeCategoryName',
    label: '证书分类',
    minWidth: '134px',
  },
  {
    prop: 'gradeListStr',
    label: '证书等级',
    minWidth: '146px',
    twoLines: true,
  },
  {
    prop: 'issuingAgency',
    label: '发证机构',
    minWidth: '150px',
    twoLines: true,
  },
  {
    prop: 'cityStr',
    label: '证书归属地',
    minWidth: '136px',
    twoLines: true,
  },
  {
    prop: 'submitTime',
    label: '提交时间',
    minWidth: '150px',
    sortdata: 1,
  },
  {
    prop: 'reviewStatus',
    label: '证书状态',
    minWidth: '76px',
    formatter: row => {
      if (row.reviewStatus == 0) {
        return '待审核'
      } else if (row.reviewStatus == 1) {
        return '已通过'
      } else {
        return '未通过'
      }
    },
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '详情',
    method: _this.handleDetail,
  },
]
import total from '../../../assets/address/city'
import TableList from '@/components/TableList'
import {
  selectType,
  certificateList,
  certificatelExport,
  delCertificate,
  beforeDelCertificate,
} from '@/api/certificate'
import to from 'await-to'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      tableTitle,
      btns,
      searchForm,
      columns,
      operates,
      tabsList,
      loading: false,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      total,
      queryParams: {},
      select: [],
      list: [],
      selectTypeList: [],
      pickerDate: {
        onPick: ({ minDate }) => {
          this.pickerMinDate = minDate.getTime()
        },
        disabledDate: time => {
          const year = 365 * 24 * 3600 * 1000
          let oneYearLater = this.pickerMinDate + year
          return time.getTime() > oneYearLater //注意是||不是&&
        },
      },
    }
  },
  created() {
    this.selectTypeData()
    this.certificateList()
  },
  methods: {
    async certificateList() {
      this.loading = true
      const { current, size } = this.pager
      const [res, err] = await to(certificateList({ current, size, ...this.queryParams }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.list = res.data.list.map(item => ({
        ...item,
        cityStr: item.provinceName + '-' + item.cityName,
        gradeListStr: item.gradeList.length
          ? item.gradeList.map(item => item.value).join('、')
          : '—',
      }))
      this.pager.total = res.data.total
    },
    async selectTypeData() {
      const [res, err] = await to(selectType({}))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.selectTypeList = res.data
    },
    // 查询按钮
    onSearch(queryParams) {
      console.log(queryParams)
      this.pager.current = 1
      if (queryParams.time) {
        queryParams.startTime = queryParams.time[0]
        queryParams.endTime = queryParams.time[1]
      } else {
        queryParams.startTime = ''
        queryParams.endTime = ''
      }
      if (queryParams.cityCode) {
        queryParams.cityCode = queryParams.cityCode[1]
      } else {
        queryParams.cityCode = ''
      }
      this.queryParams = queryParams
      this.certificateList()
    },
    handleSelectionChange(val) {
      this.select = val
    },
    handleAdd() {
      this.$router.push('/certificate/add')
    },
    async handleExport() {
      let certIdList = []
      this.select.map(v => certIdList.push(v.certId))
      const { current, size } = this.pager
      const [res, err] = await to(
        certificatelExport({ current, size, ...this.queryParams, certIdList: certIdList }),
      )
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '证书列表.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    async beforeDelCertificate() {
      let arr = []
      this.select.map(v => arr.push(v.certId))
      const [res, err] = await to(beforeDelCertificate({ certIdList: arr }))
      if (err) return this.$message.warning(err.msg)
      return res.data
    },
    async handleDel() {
      if (!this.select.length) return this.$message.warning('请先选择要删除的数据')
      if (this.beforeDelCertificate()) {
        this.$confirm('您确定要删除所选证书吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          let certId = []
          this.select.map(v => certId.push(v.certId))
          const [, err] = await to(delCertificate({ certId: certId }))
          if (err) return this.$message({ type: 'error', message: err.msg })
          this.$message({ type: 'success', message: '删除成功' })
          this.certificateList()
        })
      } else {
        this.$message.warning('当前证书有关联的证书商品，请删除所有与关联的证书商品，才能删除证书')
      }
    },
    handleDetail(row) {
      this.$router.push({
        path: '/certificate/detail',
        query: {
          certId: row.certId,
        },
      })
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.certificateList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.certificateList()
    },
  },
}
</script>

<style scoped lang="scss"></style>
